import { useState, useEffect } from 'react'
import { auth, cookieName, maxAgeCookie } from '@design-system/utils/firebaseAuthentication'
import { onAuthStateChanged, onIdTokenChanged } from '@firebase/auth'
import { setCookie } from 'cookies-next'
import { datadogRum } from '@datadog/browser-rum'
import axios from "axios";

export type LoggedStateType = {
    isLoggedIn: boolean
    isLoading: boolean
    idToken: string
}


export const useAuthentication = () => {
    const [loggedState, setLoggedState] = useState({
        isLoggedIn: false,
        isLoading: true,
        idToken: '',
    })

    const setLoggedStateWrapper = (newState: LoggedStateType) => {
        if (newState.idToken?.length > 0) {
            setCookie(cookieName, newState.idToken, {
                maxAge: maxAgeCookie,
            })
        }

        setLoggedState({ ...newState })
    }

    const refreshToken = async (forceRefresh = true) => {
        if (auth.currentUser) {
            try {
                const token = await auth.currentUser.getIdToken(forceRefresh)
                if (!token) return null

                setLoggedStateWrapper({
                    isLoggedIn: true,
                    isLoading: false,
                    idToken: token,
                })

                return token
            } catch (error) {
                datadogRum.addError(error)
                return null
            }
        }
        return null
    }

    // Auth state listener
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const token = await refreshToken(false)
                if (!token) return
            } else {
                setLoggedStateWrapper({
                    isLoggedIn: false,
                    isLoading: false,
                    idToken: '',
                })
            }
        })

        return () => unsubscribe()
    }, [])


    // Token change listener
    useEffect(() => {
        const unsubscribe = onIdTokenChanged(auth, async (user) => {
            try {
                if (user) {
                    const token = await refreshToken(true)
                    if (!token) return
                } else {
                    setLoggedStateWrapper({
                        isLoggedIn: false,
                        isLoading: false,
                        idToken: '',
                    })
                }
            } catch (error) {
                datadogRum.addError(error)
                setLoggedStateWrapper({
                    isLoggedIn: false,
                    isLoading: false,
                    idToken: '',
                })
            }
        })

        return () => unsubscribe()
    }, [])

    // Axios interceptors
    useEffect(() => {
        // Request interceptor
        const requestInterceptor = axios.interceptors.request.use(
            async (config) => {
                if (auth.currentUser) {
                    try {
                        const token = await refreshToken(true)
                        if (token) {
                            config.headers.Authorization = `Bearer ${token}`
                        }
                    } catch (error) {
                        datadogRum.addError(error)
                    }
                }
                return config
            },
            (error) => {
                return Promise.reject(error)
            }
        )

        // Response interceptor
        const responseInterceptor = axios.interceptors.response.use(
            (response) => response,
            async (error) => {
                const originalRequest = error.config

                // Handle 401 errors and token refresh
                if (error.response?.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true

                    try {
                        const token = await refreshToken(true)
                        if (token) {
                            originalRequest.headers.Authorization = `Bearer ${token}`
                            return axios(originalRequest)
                        }
                    } catch (refreshError) {
                        datadogRum.addError(refreshError)
                        setLoggedStateWrapper({
                            isLoggedIn: false,
                            isLoading: false,
                            idToken: '',
                        })
                    }
                }

                return Promise.reject(error)
            }
        )

        // Cleanup
        return () => {
            axios.interceptors.request.eject(requestInterceptor)
            axios.interceptors.response.eject(responseInterceptor)
        }
    }, [])

    return {
        ...loggedState,
        setLoggedState: setLoggedStateWrapper,
        auth,
    }
}
