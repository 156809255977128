import { BUILDING_CALLS, BuildingCallsBasicKey } from '@api/buildings/buildingsCalls'
import { GetBuildingMeasures, GetBuildingMeasuresFaq } from '@api/buildings/buildingsTypes'
import { useEnterApi, useEnterQuery } from '@design-system/api/apiContext'
import { EnterQueryOptions } from '@design-system/api/apiFactory'
import { BuildingMeasuresValues, GetBuildings } from '@design-system/types/buildings/buildingsTypes'
import { QueryKey } from '@tanstack/react-query'

export const useGetAllBuildings = ({ enabled = true }) => {
    const enterApi = useEnterApi()

    const { getKey, call } = BUILDING_CALLS.queries[BuildingCallsBasicKey.GET_ALL_BUILDINGS]

    return useEnterQuery<GetBuildings[]>(getKey(), () => call(enterApi), {
        enabled,
        retry: 5,
    })
}

export const useGetBuildingByUuid = ({
    buildingUuid,
    enabled,
    ...rest
}: EnterQueryOptions<GetBuildings, QueryKey> & { buildingUuid: string }) => {
    const enterApi = useEnterApi()

    const { getKey, call } = BUILDING_CALLS.queries[BuildingCallsBasicKey.GET_BUILDING]

    return useEnterQuery<GetBuildings>(getKey(buildingUuid), () => call(enterApi, { buildingUuid }), {
        enabled: Boolean(buildingUuid && enabled),
        ...rest,
    })
}

export const useGetBuildingMeasures = ({
    buildingUuid,
    isEnabled = true,
    retry = false,
}: {
    buildingUuid: string
    isEnabled?: boolean
    retry?: boolean | number
}) => {
    const enterApi = useEnterApi()

    const { getKey, call } = BUILDING_CALLS.queries[BuildingCallsBasicKey.GET_BUILDING_MEASURES]

    return useEnterQuery<GetBuildingMeasures>(getKey(buildingUuid), () => call(enterApi, buildingUuid), {
        enabled: !!buildingUuid && isEnabled,
        retry,
    })
}

type UseGetBuildingMeasuresFaq = {
    buildingUuid: string
    rawSubsidizableMeasureComponent: BuildingMeasuresValues | undefined
}

export const useGetBuildingMeasuresFaq = ({
    buildingUuid,
    rawSubsidizableMeasureComponent,
}: UseGetBuildingMeasuresFaq) => {
    const enterApi = useEnterApi()

    // We can call the api with an empty string, because the enabled flag is set in react-query.
    const subsidizableMeasureComponent = rawSubsidizableMeasureComponent || ('' as BuildingMeasuresValues)

    const { getKey, call } = BUILDING_CALLS.queries[BuildingCallsBasicKey.GET_BUILDING_MEASURES_FAQ]

    return useEnterQuery<GetBuildingMeasuresFaq>(
        getKey(buildingUuid, subsidizableMeasureComponent),
        () => call(enterApi, buildingUuid, subsidizableMeasureComponent),
        { enabled: Boolean(buildingUuid && subsidizableMeasureComponent) }
    )
}
