import { User } from '@pages/api/auth/me'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Page } from '@utils/urls'
import axios from 'axios'
import { getCookie } from 'cookies-next'
import { useEnterAuthConfig } from '@design-system/utils/authentication'
import { signOut } from '@design-system/utils/firebaseAuthentication'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

export const useMe = (ignoreError = false): { me: User; isMeLoading: boolean } => {
    const qc = useQueryClient()

    const router = useRouter()
    const isLoginPage = router.asPath.startsWith(Page.LOGIN) || router.asPath.includes('/auth/')

    const { authCookieName } = useEnterAuthConfig()
    const idToken = getCookie(authCookieName)
    const isIdTokenPresent = Boolean(idToken)

    const [abortController] = useState(new AbortController())

    useEffect(() => {
        if (!ignoreError && !isLoginPage && !isIdTokenPresent) {
            abortController.abort()
            router.push(Page.LOGIN)
        }
    }, [isIdTokenPresent, isLoginPage, abortController, router, ignoreError])

    const {
        data: me,
        isError,
        isLoading: isMeLoading,
    } = useQuery({
        queryKey: ['me'],
        queryFn: async () => {
            const { data } = await axios<User>('/api/auth/me', {
                headers: {
                    'x-id-token': idToken,
                },
                signal: abortController.signal,
            })

            return data
        },
        enabled: router.isReady && isIdTokenPresent,
    })

    useEffect(() => {
        ;(async () => {
            if (isError) {
                await signOut()
                qc.clear()

                if (!isLoginPage && !ignoreError) {
                    router.push(Page.LOGIN)
                }
            }
        })()
    }, [isError, router, isLoginPage, qc, ignoreError])

    return {
        me: me!,
        isMeLoading,
    }
}
