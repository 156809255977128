import { GetBuildingArgs, GetBuildingMeasures, GetBuildingMeasuresFaq } from '@api/buildings/buildingsTypes'
import { createApiUri, EnterApi } from '@design-system/api/apiFactory'
import { BuildingMeasuresValues, BuildingsInclude, GetBuildings } from '@design-system/types/buildings/buildingsTypes'

export interface SubmitBuildingPayload {
    [key: string]: unknown
}

export enum BuildingCallsBasicKey {
    GET_ALL_BUILDINGS = 'getAllBuildings',
    GET_BUILDING = 'getBuilding',
    GET_BUILDING_MEASURES = 'getBuildingMeasures',
    GET_BUILDING_MEASURES_FAQ = 'getBuildingMeasuresFaq',
}

const BUILDING_INCLUDE = {
    renovationInterests: true,
    'currentRoboAdviceVersion.roboAdviceScores': true,
    'currentRoboAdviceVersion.recommendedMeasures': true,
    'serviceLineItems.bafaVollmachtDocuments': true,
    'serviceLineItems.areInitialRequiredUserDocumentsUploaded': true,
    'serviceLineItems.subsidiesService': true,
    'serviceLineItems.isBafaPowerOfAttorneyDocumentUploaded': true,
    roofShape: true,
    address: true,
    ownerUUID: true,
}

export const BUILDING_CALLS = {
    queries: {
        [BuildingCallsBasicKey.GET_ALL_BUILDINGS]: {
            getKey: () => [BuildingCallsBasicKey.GET_ALL_BUILDINGS],
            call: (enterApi: EnterApi) =>
                enterApi
                    .get<GetBuildings[]>(
                        createApiUri<BuildingsInclude>('/main/buildings', {
                            address: true,
                        })
                    )
                    .then(({ data }) => data),
        },

        [BuildingCallsBasicKey.GET_BUILDING]: {
            getKey: (buildingUuid: string) => [BuildingCallsBasicKey.GET_BUILDING, buildingUuid],
            call: (enterApi: EnterApi, args: GetBuildingArgs) =>
                enterApi
                    .get<GetBuildings>(
                        createApiUri<BuildingsInclude>(`/main/buildings/${args.buildingUuid}`, BUILDING_INCLUDE, {})
                    )
                    .then(({ data }) => data),
        },

        [BuildingCallsBasicKey.GET_BUILDING_MEASURES]: {
            getKey: (buildingUuid: string) => [BuildingCallsBasicKey.GET_BUILDING_MEASURES, buildingUuid],
            call: (enterApi: EnterApi, buildingUuid: string) =>
                enterApi
                    .get<GetBuildingMeasures>(createApiUri<null>(`/main/buildings/${buildingUuid}/measures`, {}))
                    .then(({ data }) => data),
        },

        [BuildingCallsBasicKey.GET_BUILDING_MEASURES_FAQ]: {
            getKey: (buildingUuid: string, subsidizableMeasureComponent: BuildingMeasuresValues) => [
                BuildingCallsBasicKey.GET_BUILDING_MEASURES_FAQ,
                buildingUuid,
                subsidizableMeasureComponent,
            ],
            call: (enterApi: EnterApi, buildingUuid: string, subsidizableMeasureComponent: BuildingMeasuresValues) =>
                enterApi
                    .get<GetBuildingMeasuresFaq>(
                        createApiUri<null>(`/main/buildings/${buildingUuid}/faq/${subsidizableMeasureComponent}`, {})
                    )
                    .then(({ data }) => data),
        },
    },
}
